import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import Tooltip from '@material-ui/core/Tooltip';
import { I18n, Translate } from 'react-redux-i18n';
import ContentLoader from '../../ContentLoader';
import Button from '../../Button';
import useClickOutside from '../../../hooks/useClickOutside';
import api from '../../../api/apiClient';
import { showNotification } from '../../../actions';
import styles from './ProgramManagement.module.scss';
import moment from 'moment';

export type Program = {
  id: string;
  name: string;
  state: string;
  created: string;
  ongoingAppointment: boolean;
  availableActions: ProgramAction[];
  reason?: string;
  reasonCategory?: string;
  reasonMessage?: string;
};

type ProgramAction = {
  type: string;
  availableOptions: string[];
};

type Props = {
  patientId: string;
  authToken: string;
  showNotification: FixMe;
};

function ProgramManagement({ patientId, authToken, showNotification }: Props) {
  const [editingId, setEditingId] = useState<string | undefined>();
  const [programs, setPrograms] = useState<Program[]>([]);
  const [error, setError] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const HIDDEN_LIMIT = 5;
  const visiblePrograms = expanded ? programs : programs.slice(0, HIDDEN_LIMIT + 1);

  useEffect(() => {
    getPrograms();
  }, []);

  const getPrograms = () => {
    setError(undefined);
    setIsLoading(true);

    api
      .getPatientPrograms(authToken, patientId)
      .then((res) => {
        if (res.ok) {
          res.json().then(setPrograms);
        } else {
          throw new Error('Could not fetch programs.');
        }
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
  };

  const modifyProgram = (program: Program) => {
    return function (action: string) {
      if (action.toLowerCase() === 'abort') {
        if (window.confirm(I18n.t('member_details.program_management.confirm_abort'))) {
          patchProgram(program.id, action);
        }
      } else {
        patchProgram(program.id, action);
      }
    };
  };

  const patchProgram = async (programId: string, action: string) => {
    try {
      const response = await api.updatePatientProgram(authToken, patientId, programId, action);

      if (!response.ok) {
        throw new Error('Could not modify program');
      }

      setEditingId(undefined);
      getPrograms();
    } catch (e) {
      console.error(e);
      showNotification(I18n.t('notification.update_program.error'), 'error');
    }
  };

  return (
    <div>
      <h2>
        <Translate value="member_details.program_management.header" />
      </h2>
      <ContentLoader isLoading={isLoading} error={error} errorTitleI18n="member_details.program_management.error">
        {!programs?.length ? (
          <Translate value="member_details.program_management.empty" />
        ) : (
          <>
            <div className="columns">
              <div className="column is-2 pb-0">
                <h4>Datum</h4>
              </div>
              <div className="column is-6 pb-0">
                <h4>Namn</h4>
              </div>
              <div className="column is-2 pb-0">
                <h4>Status</h4>
              </div>
              <div className="column is-1 pb-0">
                <h4>Aktivt vårdbesök</h4>
              </div>
              <div className="column is-3 pb-0"></div>
            </div>
            <div
              className={`${styles.programListWrapper} ${
                !expanded && programs.length > HIDDEN_LIMIT ? styles.contentHidden : ''
              }`}
            >
              <AnimatePresence>
                {visiblePrograms.map((program) => {
                  return (
                    <motion.div
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, y: -30, height: 0 }}
                      transition={{ ease: 'easeOut', duration: 0.3 }}
                      className={`columns ${editingId === program.id ? 'fw-bold' : ''} ${styles.row}`}
                      key={program.id}
                    >
                      <div className="column is-2">{moment(program.created).format('D MMM -YY')}</div>
                      <div
                        className="column is-6 overflow-ellipsis"
                        title={I18n.t(`member_details.program_management.program.${program.name}`)}
                      >
                        <Translate value={`member_details.program_management.program.${program.name}`} />
                      </div>
                      <div className="column is-2 vertical-align">
                        <Translate
                          value={`member_details.program_management.program_state.${program.state.toLowerCase()}`}
                        />
                        {program.reasonCategory || program.reason ? (
                          <Tooltip
                            title={
                              <div className="fs-12">
                                {program.reasonCategory ? (
                                  <div>
                                    <Translate
                                      value={`member_details.program_management.reason_category.${program.reasonCategory.toLowerCase()}`}
                                    />
                                  </div>
                                ) : null}
                                {program.reason ? (
                                  <div>
                                    <Translate
                                      value={`member_details.program_management.reason.${program.reason.toLowerCase()}`}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            }
                            arrow
                            placement="top"
                          >
                            <span className="icon question-mark ml-5"></span>
                          </Tooltip>
                        ) : null}
                      </div>
                      <div className="column is-1">
                        {program.ongoingAppointment ? (
                          <Translate value="global.yes" />
                        ) : (
                          <Translate value="global.no" />
                        )}
                      </div>
                      <div className="column is-3">
                        {editingId === program.id ? (
                          <div className="vertical-align">
                            <ActionList actions={program.availableActions} onModify={modifyProgram(program)} />
                            <span
                              className="icon x-small cross pointer ml-10"
                              onClick={() => setEditingId(undefined)}
                            ></span>
                          </div>
                        ) : program.availableActions.length ? (
                          <span className={styles.edit} onClick={() => setEditingId(program.id)}>
                            <Translate value="member_details.program_management.edit" />
                          </span>
                        ) : null}
                      </div>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
            {programs.length > HIDDEN_LIMIT ? (
              <div className="text-center">
                <span className="text-button" onClick={() => setExpanded(!expanded)}>
                  <Translate value={`global.show_${expanded ? 'fewer' : 'all'}`} />
                </span>
              </div>
            ) : null}
          </>
        )}
      </ContentLoader>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.auth.token.jwt
});

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(ProgramManagement);

type ActionListProps = {
  actions: ProgramAction[];
  onModify(type: string): void;
};

const ActionList = ({ actions, onModify }: ActionListProps) => {
  const [toggled, setToggled] = useState(false);
  const toggle = () => setToggled(!toggled);
  const ref = useClickOutside(() => setToggled(false));

  const modify = (type: string) => {
    onModify(type);
    setToggled(false);
  };

  const menuVariants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -10 }
  };

  return (
    //@ts-ignore
    <div ref={ref} className={styles.actionListWrapper}>
      {actions.length ? (
        <Button size="medium" onClick={toggle}>
          <Translate value="member_details.program_management.action_trigger" />
          <span className={`${styles.chevron} ${toggled ? 'rotated' : ''}`}></span>
        </Button>
      ) : null}
      <motion.div
        className={styles.actionItems}
        style={{ display: toggled ? 'block' : 'none' }}
        initial="hidden"
        animate={toggled ? 'open' : 'hidden'}
        variants={menuVariants}
        transition={{ duration: 0.1 }}
      >
        <div>
          {actions.map((action, i) => (
            <Button
              size="medium"
              buttonType={action.type === 'ABORT' ? 'destructive' : 'secondary'}
              key={i}
              onClick={() => modify(action.type)}
            >
              <Translate value={`member_details.program_management.action.${action.type.toLowerCase()}`} />
            </Button>
          ))}
        </div>
      </motion.div>
    </div>
  );
};
